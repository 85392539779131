import { Injectable } from "@angular/core"
import { Observable, map, from } from "rxjs"
import { defaultDriversOpts, IReqFleetDrivers, IResFleetAdminDriversRow, IResFleetDriversRow } from "src/app/concepts/api/lightmetrics/fleets/drivers/get-fleet-drivers"
import { IReqFleetViolations, defaultViolationOpts, IResFleetViolationsRow } from "src/app/concepts/api/lightmetrics/fleets/incidents/get-fleet-violations"
import { IReqFleetTrips, defaultTripOpts, ITrip, defaultTripOptsV2, IReqFleetTripsV2, ITripV2, IVehicleIdleTime } from "src/app/concepts/api/lightmetrics/fleets/trips/get-fleet-trips"
import { IReqDriverTrips, defaultDriverTripOpts, IResDriverTripsRow } from "src/app/concepts/api/lightmetrics/fleets/drivers/get-driver-trips"
import { LightmetricsService } from "../services/lightmetrics-service"
import { defaultAggregateTripOpts, IReqAggregateFleetTrips, IResAggregateFleetTripsRow } from "src/app/concepts/api/lightmetrics/fleets/trips/get-aggregate-fleet-trips"
import { IReqFleetCoaching, IResFleetCoachingRow, defaultFleetCoachingOpts } from "src/app/concepts/api/lightmetrics/fleets/coaching/get-fleet-coaching"
import { defaultReqDriverTripDetails, IReqDriverTripDetails, IResDriverTripDetails } from "src/app/concepts/api/lightmetrics/fleets/drivers/get-driver-trip-details"
import { IResDriverEventDetails } from "src/app/concepts/api/lightmetrics/fleets/drivers/get-driver-event-details"
import { defaultAggregateDriverTripOpts, IReqAggregateAssetTrips, IReqAggregateDriverTrips, IResAggregateAssetTripsRow, IResAggregateDriverTripsRow } from "src/app/concepts/api/lightmetrics/fleets/drivers/get-driver-aggregate-trips"
import { IReqOngoingTrips, IResOngoingTripsRow, defaultOngoingTripsOpts } from "src/app/concepts/api/lightmetrics/fleets/trips/get-ongoing-trips"
import { IReqMessageEventStream, IResMessageEventStream, defaultMessageEventStreamOpts, defaultResetEventStreamOpts, IReqResetEventStream } from "src/app/concepts/api/lightmetrics/events/event-stream"
import { defaultAssetsOpts, IReqFleetAssets, IResFleetAssetsRow } from "src/app/concepts/api/lightmetrics/fleets/assets/get-fleet-assets"
import { VikingAPIService } from "../services/viking-api-service"
import { IDeviceState } from "src/app/concepts/devices/devices"
import { IReqRequestLiveStream, IResRequestLiveStream, IReqStopLiveStream } from "src/app/concepts/api/lightmetrics/live-streaming/live-stream-requests"

export interface ICacheOptions {
  after?: string
  before?: string
  limit: number
  skip: number
  groupBy?: 'day' | 'week' | 'month'
  dutyType?: 'light' | 'medium' | 'heavy'
}

export class Cache<T> {
  private _datasource: Function
  private _cacheName: string
  private _cachePage = 0
  private _cacheUpdated = 0
  private _options: any

  public get updated(): number { return this._cacheUpdated }

  constructor(name: string, datasource: Function, options: any) {
    this._cacheName = name
    this._datasource = datasource
    this._cacheUpdated = Date.now()
    this._options = options
    this.resetCache()
  }

  public is(name: string) : boolean {
    return this._cacheName === name
  }

  public resetCache() : void {
    this._cachePage = 0
  }

  public getResult(parameters?:any[]) : Observable<T> {
    return this._datasource(this._options, ...parameters)
  }

  public getResults(parameters?:any[]) : Observable<T[]> {
    return this._datasource(this._options, ...parameters)
  }

  public getPage(page: number) : Observable<T[]> {
    this._cachePage = page
    return this._datasource(this._options)
  }

  public nextPage() : Observable<T[]> {
    // TODO - look at API to get limits so we can cap the page
    return this.getPage(this._cachePage+1)
  }

  public prevPage() : Observable<T[]> {
    return this.getPage(this._cachePage > 0 ? this._cachePage - 1 : 0)
  }

}

@Injectable({
  providedIn: 'root',
})
export class FleetDataLayer {
  tripDetailsDatasourceV2(tripId: string) {
    throw new Error('Method not implemented.')
  }

  private caches: Cache<any>[] = []

  constructor(
    private lm: LightmetricsService,
    private viking: VikingAPIService
  ) {
  }

  public addCache<T>(name: string, datasource: Function, options: any, forceUpdate = false): void {
    // This requires a little more finesse, like remembering options and comparing
    // existing to new to see if the request needs updating.
    // For now let's just assume the same name = the same data, making sure we get fresh data
    // by removing the cache and then re-adding it
    const existing = this.caches.find(c => c.is(name))
    if(forceUpdate) {
      this.removeCache(name)
    }
    if(forceUpdate || !existing) {
      this.caches.push(new Cache<T>(name, datasource, options));
    }
  }

  public removeCache(name: string) : void {
    this.caches = this.caches.filter(entry => !entry.is(name))
  }

  public getCache(name: string) : Cache<any> {
    return this.caches.find(c => c.is(name))
  }

  public getCacheType<T>(name: string) : Cache<T> {
    return this.caches.find(c => c.is(name))
  }

  public getTrips(options?: IReqFleetTrips) : Observable<ITrip[]> {
    options = options ? options : defaultTripOpts
    return this.viking.getTrips(options).pipe(map(res => res.rows))
  }

  public getTripsStops(options?: IReqFleetTrips) : Observable<IVehicleIdleTime[]> {
    options = options ? options : defaultTripOpts
    return this.viking.getTripsStops(options).pipe(map(res => res.rows))
  }

  public getTripsV2(options?: IReqFleetTripsV2) : Observable<ITripV2[]> {
    options = options ? options : defaultTripOptsV2;
    return this.viking.getTripsV2(options)
  }

  public getViolations(options?: IReqFleetViolations) : Observable<IResFleetViolationsRow[]> {
    options = options ? options : defaultViolationOpts
    return this.viking.getViolations(options).pipe(map(res => res?.rows))
  }

  public getViolationsV2(options?: IReqFleetViolations) : Observable<IResFleetViolationsRow[]> {
    options = options ? options : defaultViolationOpts
    return this.viking.getViolationsV2(options)
  }

  public getDrivers(options?: IReqFleetDrivers) : Observable<IResFleetDriversRow[]> {
    console.log('**DRIVERS** FDL options', options)

    options = options ? options : defaultDriversOpts
    return this.viking.getDrivers(options).pipe(map(res => res?.rows))
  }

  public getDriversAdmin(options?: IReqFleetDrivers) : Observable<IResFleetAdminDriversRow[]> {
    console.log('**DRIVERS** FDL options', options)

    options = options ? options : defaultDriversOpts
    return this.viking.listVikingDrivers().pipe(map(res => res))
  }

  public getAssets(options?: IReqFleetAssets) : Observable<IResFleetAssetsRow[]> {
    options = options ? options : defaultAssetsOpts
    return this.viking.getAssets(options).pipe(map(res => res.rows))
  }

  // public getDriverTrips(driverId: string, options?: IReqDriverTrips) : Observable<IResDriverTripsRow[]> {
  //   options = options ? options : defaultDriverTripOpts
  //   return this.lm.getDriverTrips(driverId, options).pipe(map(res => res.rows))
  // }

  public getDriverViolations(driverId: string, options?: IReqFleetViolations) : Observable<IResFleetViolationsRow[]> {
    options = options ? options : defaultViolationOpts
    return this.viking.getDriverViolations(driverId, options).pipe(map(res => res.rows))
  }

  public getAssetViolations(assetId: string, options?: IReqFleetViolations) : Observable<IResFleetViolationsRow[]> {
    options = options ? options : defaultViolationOpts
    return this.viking.getAssetViolations(assetId, options).pipe(map(res => res.rows))
  }

  public getAggregateTrips(options?: IReqAggregateFleetTrips) : Observable<IResAggregateFleetTripsRow[]> {
    options = options ? options : defaultAggregateTripOpts
    return this.viking.getAggregateFleetTrips(options).pipe(map(res => res.rows))
  }

  public getAggregateTripsV2(options?: IReqAggregateFleetTrips) : Observable<IResAggregateFleetTripsRow[]> {
    options = options ? options : defaultAggregateTripOpts
    return this.viking.getAggregateFleetTripsV2(options)
  }
  
  public getAggregateViolationsV2(options?: IReqAggregateFleetTrips) : Observable<IResAggregateFleetTripsRow[]> {
    options = options ? options : defaultAggregateTripOpts
    return this.viking.getAggregateFleetViolationsV2(options)
  }

  private _aggregateEventCount(src: any) : any {
    let dest = {}
    Object.keys(src).forEach(key => {
      if(dest.hasOwnProperty(key)) {
        dest[key] = dest[key] + src[key]
      } else {
        dest[key] = src[key]
      }
    })
    return dest
  }

  public getAggregateDriverTrips(driverId: string, options?: IReqAggregateDriverTrips) : Observable<IResAggregateDriverTripsRow> {
    options = options ? options : defaultAggregateDriverTripOpts
    return this.viking.getAggregateDriverTrips(driverId, options).pipe(map(res => {
      return res?.rows?.reduce((acc: IResAggregateDriverTripsRow, row: IResAggregateDriverTripsRow) => {

        let outer = {}
        Object.keys(row.value).forEach(key => {
          if(outer.hasOwnProperty(key)) {
            key === 'eventCount'
              ? this._aggregateEventCount(row.value.eventCount)
              : outer[key] = outer[key] + row.value.eventCount[key]
          } else {
            outer[key] = row.value[key]
          }
        })
        return outer
      }, {})
    }))
  }

  public getAggregateAssetTrips(assetId: string, options?: IReqAggregateAssetTrips) : Observable<IResAggregateAssetTripsRow> {
    options = options ? options : defaultAggregateDriverTripOpts // not typo same opts
    return this.viking.getAggregateAssetTrips(assetId, options).pipe(map(res => {
      return res.rows?.reduce((acc: IResAggregateAssetTripsRow, row: IResAggregateAssetTripsRow) => {

        let outer = {}
        Object.keys(row.value).forEach(key => {
          if(outer.hasOwnProperty(key)) {
            key === 'eventCount'
              ? this._aggregateEventCount(row.value.eventCount)
              : outer[key] = outer[key] + row.value.eventCount[key]
          } else {
            outer[key] = row.value[key]
          }
        })
        return outer
      }, {})
    }))
  }

  public getFleetCoaching(options?: IReqFleetCoaching) : Observable<IResFleetCoachingRow[]> {
    options = options ? options : defaultFleetCoachingOpts
    return this.viking.getFleetCoaching(options).pipe(map(res => res.rows))
  }

  public getDriverTripDetails(driverId: string, tripId: string, options?: IReqDriverTripDetails) : Observable<IResDriverTripDetails> {
    options = options ? options : defaultReqDriverTripDetails
    return from(this.viking.getDriverTripDetails(driverId, tripId, options))
  }

  public geTripDetailsV2(tripId: string,fleetId: string, options?: IReqDriverTripDetails) : Observable<IResDriverTripDetails> {
    options = options ? options : defaultReqDriverTripDetails
    return from(this.viking.getTripDetailsV2(tripId, fleetId, options))
  }

  public getDriverEventDetails(driverId: string, tripId: string, eventIndex: number, options?: IReqDriverTripDetails) : Observable<IResDriverEventDetails> {
    options = options ? options : defaultReqDriverTripDetails
    return this.viking.getDriverEventDetails(driverId, tripId, eventIndex, options)
  }

  // DEPRECATED
  // public getOngoingTrips(options?: IReqOngoingTrips) : Observable<IResOngoingTripsRow[]> {
  //   options = options ? options : defaultOngoingTripsOpts
  //   return this.viking.getOngoingTrips(options).pipe(map(res => res?.rows))
  // }

  public getEventStream(options?: IReqMessageEventStream) : Observable<IResMessageEventStream> {
    options = options ? options : defaultMessageEventStreamOpts
    return this.lm.getEventStream(options)
  }

  public resetEventStream(options?: IReqResetEventStream) : Observable<any> {
    options = options ? options : defaultResetEventStreamOpts
    return this.lm.resetEventStream(options)
  }

  // I would much prefer to use Promises for everything... big refactor coming.

  public async getDevices() : Promise<IDeviceState[] | undefined> {
    return await this.viking.getDevices()
  }

  // public async getDevicesV2() : Promise<any[]> {
  //   return await this.viking.getDevicesV2()
  // }

  public async getDevicesV2() : Promise<any[]> {
    return await this.viking.getDeviceStateV2()
  }

  public async requestLiveStreamV2(fleetId: string, deviceId: string, videoType: string) : Promise<IResRequestLiveStream> {
    // options = options ? options :
    // return this.lm.requestLiveStream(options)
    return this.viking.requestLiveStreamV2(fleetId, deviceId, videoType)
  }

  public async stopLiveStreamV2(fleetId: string, streamRequestId: string) : Promise<any> {
    // return this.lm.stopLiveStream(options)
    return this.viking.stopLiveStreamV2(fleetId, streamRequestId)
  }

  // Deprecated
  public async requestLiveStream(options?: IReqRequestLiveStream) : Promise<IResRequestLiveStream> {
    // options = options ? options :
    return this.lm.requestLiveStream(options)
  }

  // Deprecated
  public async stopLiveStream(options?: IReqStopLiveStream) : Promise<any> {
    return this.lm.stopLiveStream(options)
  }

}
