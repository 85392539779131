import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, Observable, of, scan, Subscription, take } from 'rxjs';
import { Message } from 'src/app/concepts/api/lightmetrics/events/event-stream';
import { IResOngoingTripsRow } from 'src/app/concepts/api/lightmetrics/fleets/trips/get-ongoing-trips';
import { ILiveMapDriver, ILiveViewAssetTableResult } from 'src/app/concepts/live-view/live-view';
import { FleetDataLayer } from 'src/app/providers/data-layers/fleet-data-layer';
import { environment } from 'src/environments/environment';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { FormControl, FormGroup } from '@angular/forms';
import { IDeviceState } from 'src/app/concepts/devices/devices';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { FleetService } from 'src/app/providers/services/fleet-service';
import { IPortalUser, UserService } from 'src/app/providers/services/user-service';
import moment from 'moment';
import { IActionCollection } from 'src/@vex/components/widgets/widget-table/widget-table.component';
import { StreamingDialogComponent } from './dialogs/streaming/streaming-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { AlertDialogComponent } from 'src/@vex/components/dialogs/alert/alert-dialog.component';
import { VikingAPIService } from 'src/app/providers/services/viking-api-service';

interface IVehicleCacheEntry {
  message: Message
  ongoingTripData: IResOngoingTripsRow
  parked: boolean
  insertion: string
}

interface IVehicleCache {
  [key: string]: IVehicleCacheEntry
}

@Component({
  selector: 'vex-live-view',
  templateUrl: './live-view.component.html',
  styleUrls: ['./live-view.component.scss']
})
export class LiveViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('map') map:google.maps.Map
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow
  @ViewChildren('marker') markers: QueryList<any>

  public bounds: google.maps.LatLngBounds = null
  public filterAssetId: string = ""
  private updateFrequency: number = environment.liveView.updateFrequency
  private _markerData: ILiveMapDriver[]
  public mapData$: Observable<ILiveMapDriver[]>
  language: string = this.translate.currentLang;
  assetsList: string[] = []
  private _assets = []

  public tabIndex: number = 0;
  public activeMarkers: ILiveMapDriver[] = [];
  public inactiveMarkers: ILiveMapDriver[] = [];
  private _fleetType: boolean;
  user: string;
  private _currentUser: IPortalUser;

  // real...
  private timer : ReturnType<typeof setInterval>
  public tableData$: Observable<ILiveViewAssetTableResult[]>
  public tableDataLoaded$: Observable<boolean>
  private _rawTableData: ILiveViewAssetTableResult[]
  public infoContent: SafeHtml = 'Hello'
  public filterFormGroup: FormGroup

  ///////////////////////////////////////////////////////////////////////////////////////
  // NEW SYSTEM
  ///////////////////////////////////////////////////////////////////////////////////////
  private _devices: IDeviceState[] = []
  public markersLoaded$: Observable<boolean>
  private _infoCloseSub: Subscription
  ///////////////////////////////////////////////////////////////////////////////////////

  public mapOptions: google.maps.MapOptions = {
    ..._.cloneDeep(environment.liveView.map),
    streetViewControl: false
  };


  constructor(
    private fdl: FleetDataLayer,
    private translate: TranslateService,
    private fleetService: FleetService,
    private userService: UserService,
    public dialog: MatDialog,
    private sanitized: DomSanitizer,
    private elementRef: ElementRef,
    private vapi: VikingAPIService
  ) { 
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
  }


  async ngOnInit(): Promise<void> {
    this.filterFormGroup = new FormGroup({
      filter: new FormControl(this.filterAssetId),
    });
    this.getUser();
    this.userService.getPortalUser(this.user).then((user) => {
      this._currentUser  = user;
    });
    this._runQuery()
  }


  private async _runQuery() {
    await this.fleetService.setFleet();
    const fleetType = await this.fleetService.fleetType$.pipe(take(1)).toPromise();
    this._fleetType = fleetType;
    console.log('fleet type2', this._fleetType);

    if(this._fleetType){
      this._devices = await this.fdl.getDevicesV2()
      console.log('LIVE: Device State V2', this._devices)
      if(this._devices?.length > 0) {
        this._renderMarkersV2()
      }
    }else{
      this._devices = await this.fdl.getDevices()
      console.log('LIVE: Device State', this._devices)
      this._assets = await this.vapi.getAllFleetAssets()
      console.log('LIVE: Assets', this._assets)
      if(this._devices?.length > 0 ) {
        this._renderMarkers()
      }

    }
  }

  ngAfterViewInit(): void {

    this.timer = setInterval(() => {
      this._runQuery()
    }, this.updateFrequency)

    this._runQuery()
  }

  ngOnDestroy(): void {
    clearInterval(this.timer)
  }

  private _renderMarkersV2() {
    this.activeMarkers = [];
    this.inactiveMarkers = [];
    const assets = this._devices.map(d => d.assetId) || []
    this.assetsList = assets
    this.assetsList.sort()
    if(assets?.length > 0) {
      let index = 0
      const newBounds = new google.maps.LatLngBounds()
      const setState = (lastUpdatedTimeUTC: Date): string => {
      const twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      const isRecent = lastUpdatedTimeUTC >= twoWeeksAgo;
        return isRecent ? 'active' : 'inactive';
      }

      this._markerData = assets.filter(assetId => {
        const device = this._devices.find(d => d.assetId === assetId);

        // Check if device is defined and has location with valid latitude and longitude
        const hasValidLocation =
          device &&
          device.location !== undefined &&
          device.location.latitude !== undefined &&
          device.location.longitude !== undefined &&
          device.location.latitude !== null &&
          device.location.longitude !== null;
          
        return (
          (this.filterAssetId?.length === 0 || assetId.toLowerCase().includes(this.filterAssetId.toLowerCase())) &&
          hasValidLocation
        );
    }).map((assetId, index) => {
        const device = this._devices.find(d => d.assetId === assetId);
        const position = {
          lat: device.location.latitude,
          lng: device.location.longitude
        }
        const state = setState(new Date(device.lastUpdatedTimeUTC));
        var color = state == 'active' ? 'FF0000' : '23424242';

        if(device.deviceState === "ONGOING"){
          color = '4caf50';
        }
        newBounds.extend(position);
        const r = {
          position,
          options: {
            title: assetId,
            draggable: false,
            icon: `https://api.geoapify.com/v1/icon/?type=material&color=%23${color}&iconType=awesome&textSize=small&text=${this.assetsList.indexOf(assetId) +1 }&apiKey=8e47516235ac4f3a8f67f501b3453f4f`,
          },
          meta: {
            assetId: assetId,
            device
          }
        }
        console.log('r = ', r);
        if(state == 'active') {
          this.activeMarkers.push(r);
        } else {
          this.inactiveMarkers.push(r);
        }
        return r;
      })

      if (this.tabIndex == 0) {
        this.mapData$ = of(this.activeMarkers);
      } else {
        this.mapData$ = of(this.inactiveMarkers);
      }
      //this.mapData$ = of(this._markerData)

      console.log('OLD BOUNDS', this.bounds, 'NEW BOUNDS', newBounds)
      const reCenterMap = !this.bounds || this._markerData
      .filter(device => !!device.meta.device.location)
      .reduce((acc, device) => {
        if(!this.bounds.contains({
          lat: device.meta.device.location.latitude,
          lng: device.meta.device.location.longitude
        })) {
          acc = false
        }
        return acc
      },true)

      if(reCenterMap) {
        console.log('RECENTERING MAP!')
        this.bounds = newBounds
        this.map.fitBounds(this.bounds)
      } else {
        console.log('MAP BOUNDS ARE OK!')
      }
    } else {
      // no markers to render
      this._markerData = []
      this.mapData$ = of(this._markerData)
      this.mapOptions = {...this.mapOptions, center: environment.liveView.map.center, zoom: environment.liveView.map.defaultZoom, streetViewControl: false}
    }
    this.markersLoaded$ = of(false)
    this._updateTableDataV2()
  }

  private _renderMarkers() {
    this.activeMarkers = [];
    this.inactiveMarkers = [];
    const assets = this._devices
    .map(d => this._assets.find(asset => asset.assetId === d.assetId))
    .filter(asset => asset !== undefined) // Filter out undefined assets
    .map(asset => asset.assetName);
    this.assetsList = assets
    this.assetsList.sort()
    if(assets?.length > 0) {
      let index = 0
      const newBounds = new google.maps.LatLngBounds()
      const setState = (lastUpdatedTimeUTC: Date): string => {
      const twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      const isRecent = lastUpdatedTimeUTC >= twoWeeksAgo;
        return isRecent ? 'active' : 'inactive';
      }

      this._markerData = assets.filter(assetName => {
        const device = this._devices.find(d => d.assetId === this._assets.find(asset => asset.assetName === assetName).assetId);
        return (this.filterAssetId?.length === 0 || assetName.toLowerCase().includes(this.filterAssetId.toLowerCase())) &&
          device.lastKnownLocation?.latitude || 0 + device.lastKnownLocation?.longitude || 0 > 0;
      }).map((assetName, index) => {
        const device = this._devices.find(d => d.assetId === this._assets.find(asset => asset.assetName === assetName).assetId);
        const position = {
          lat: device.lastKnownLocation.latitude,
          lng: device.lastKnownLocation.longitude
        }
        const state = setState(new Date(device.lastUpdatedTimeUTC));
        var color = state == 'active' ? 'FF0000' : '23424242';

        if(device.lastUpdatedBy === "ONGOING"){
          color = '4caf50';
        }

        newBounds.extend(position);
        const r = {
          position,
          options: {
            title: assetName,
            draggable: false,
            icon: `https://api.geoapify.com/v1/icon/?type=material&color=%23${color}&iconType=awesome&textSize=small&text=${this.assetsList.indexOf(assetName) +1 }&apiKey=8e47516235ac4f3a8f67f501b3453f4f`,
          },
          meta: {
            assetId: assetName,
            device
          }
        }
        console.log('r = ', r);
        if(state == 'active') {
          this.activeMarkers.push(r);
        } else {
          this.inactiveMarkers.push(r);
        }
        return r;
      })

      if (this.tabIndex == 0) {
        this.mapData$ = of(this.activeMarkers);
      } else {
        this.mapData$ = of(this.inactiveMarkers);
      }
      //this.mapData$ = of(this._markerData)

      console.log('OLD BOUNDS', this.bounds, 'NEW BOUNDS', newBounds)
      const reCenterMap = !this.bounds || this._devices
      .filter(device => !!device.lastKnownLocation)
      .reduce((acc, device) => {
        if(!this.bounds.contains({
          lat: device.lastKnownLocation?.latitude,
          lng: device.lastKnownLocation?.longitude
        })) {
          acc = false
        }
        return acc
      },true)

      if(reCenterMap) {
        console.log('RECENTERING MAP!')
        this.bounds = newBounds
        this.map.fitBounds(this.bounds)
      } else {
        console.log('MAP BOUNDS ARE OK!')
      }
    } else {
      // no markers to render
      this._markerData = []
      this.mapData$ = of(this._markerData)
      this.mapOptions = {...this.mapOptions, center: environment.liveView.map.center, zoom: environment.liveView.map.defaultZoom, streetViewControl: false}
    }
    this.markersLoaded$ = of(false)
    this._updateTableData()
  }

  private _updateTableDataV2() {
    this._rawTableData = this._devices.filter(device => {
      return !!device.location && (this.filterAssetId?.length === 0 || device.assetId.toLocaleLowerCase().includes(this.filterAssetId.toLocaleLowerCase()))
    }).map<ILiveViewAssetTableResult>(device => {
      return {
        assetId: device.assetId,
        fleetId: this._currentUser.fleetId,
        index: this.assetsList.indexOf(device.assetId) + 1,
        callback: () => {
          console.log('markers', device.assetId, 'clicked', this.markers)
          this.mapOptions = {...this.mapOptions, center: {
            lat: device.location.latitude,
            lng: device.location.longitude
          }}
          const data = this._markerData.find(m => m.meta.device.assetId === device.assetId)
          const marker = this.markers.toArray().find(m => m.marker.title === device.assetId)
          this.markerClickedV2(data, marker)
        },
        // Disabled for V1.
        // actions: ["live.view.trip_details"],
        meta: {
          device
        },
        fleetType : this._fleetType
      }
    })
    console.log('Live Table Data', this._rawTableData)

    this.tableData$ = of(this._rawTableData)
    this.tableDataLoaded$ = of(true)
  }

  private _updateTableData() {
    this._rawTableData = this._devices.filter(device => {
      return !!device.lastKnownLocation && (this.filterAssetId?.length === 0 || this._assets.find(asset => asset.assetId === device.assetId).assetName.toLocaleLowerCase().includes(this.filterAssetId.toLocaleLowerCase()))
    }).map<ILiveViewAssetTableResult>(device => {
      return {
        assetId: this._assets.find(asset => asset.assetId === device.assetId).assetName,
        index: this.assetsList.indexOf(this._assets.find(asset => asset.assetId === device.assetId).assetName) + 1,
        callback: () => {
          console.log('markers', device.assetId, 'clicked', this.markers)
          this.mapOptions = {...this.mapOptions, center: {
            lat: device.lastKnownLocation.latitude,
            lng: device.lastKnownLocation.longitude
          }}
          const data = this._markerData.find(m => m.meta.device.assetId === device.assetId)
          const marker = this.markers.toArray().find(m => m.marker.title === device.assetId)
          this.markerClicked(data, marker)
        },
        // Disabled for V1.
        // actions: ["live.view.trip_details"],
        meta: {
          device
        },
        fleetType : this._fleetType
      }
    })
    console.log('Live Table Data', this._rawTableData)

    this.tableData$ = of(this._rawTableData)
    this.tableDataLoaded$ = of(true)
  }

  public markerClickedV2(data: any, marker: MapMarker): void {
    console.log('marker clicked!', data.meta.device)

    const hours = !data.meta.device.duration ? "0" : ((data.meta.device.duration / 3600) < 1) ? "00h" : `${Math.floor((data.meta.device.duration / 3600)).toString().padStart(2, '0')}h`
    const mins = !data.meta.device.duration ? "0" : `${Math.round((data.meta.device.duration % 3600) / 60).toString().padStart(2, '0')}m`
    const duration = `${hours}:${mins}`
    const distance = !data.meta.device.tripDistance ? "0 mi" : `${data.meta.device.tripDistance.toFixed(2)} mi`
    const incidentCount = !data.meta.device.eventCount ? 0 : (data.meta.device.eventCount['total'] || "0").toString()
    const driverId = !data.meta.device.driverId ? "Unknown" : data.meta.device.driverId
    const driverName = !data.meta.device.driverName ? "Unknown" : data.meta.device.driverName
    const assetId = !data.meta.device.assetId ? "Unknown" : data.meta.device.assetId
    const deviceId = !data.meta.device.assetName ? "Unknown" : data.meta.device.assetName
    const fleetId = !data.meta.device.fleetId ? this._currentUser.fleetId : data.meta.device.fleetId
    const lastUpdated = !data.meta.device.lastUpdatedTimeUTC ? "Unknown" : data.meta.device.lastUpdatedTimeUTC
    const isDriving = !data.meta.device.deviceState ? false : data.meta.device.deviceState === "ONGOING"
    var streamButton = ""

    if (isDriving === true)
      streamButton = `<div class="icon-button-container">
        <button id="open-stream-dialog-button"
            assetId="${assetId}"
            deviceId="${assetId}"
            fleetId="${fleetId}"
            type="button">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="currentColor" d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
        </button>
        <span class="tooltiptext">${this.translate.instant('streaming.streamTrip')}</span>
      </div>`

    this.infoContent = this.sanitized.bypassSecurityTrustHtml( `
      <div class="info-window-table" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">
        <div><p>${this.translate.instant('trips.tripDetails')}</p></div>
        ${streamButton}
        <div class="info-window-key-nontable">
          <p>${this.translate.instant('trips.assetId')}</p>
        </div>
        <div class="info-window-value-nontable">
          <p>
            ${assetId}
          </p>
        </div>
        <div class="info-window-key-nontable">
          <p> ${this.translate.instant('drivers.driverId')}</p>
        </div>
        <div class="info-window-value-nontable">
          <p>
            ${deviceId}
          </p>
        </div>
        <div class="info-window-key-nontable">
          <p>${this.translate.instant('trips.lastUpdated')}</p>
        </div>
        <div class="info-window-value-nontable">
          <p>
            ${moment(lastUpdated).format(moment.localeData(sessionStorage.getItem('language')).longDateFormat('lll'))}
          </p>
        </div>
      </div>`)
    
    this._infoCloseSub?.unsubscribe()
    this._infoCloseSub = this.infoWindow.closeclick.pipe(take(1)).subscribe(() => {
      this._renderMarkersV2()
    })
    this.infoWindow.open(marker)
  }

  public markerClicked(data: any, marker: MapMarker): void {
    if(this._fleetType){
      console.log('marker clicked! V2', data.meta.device)

      const hours = !data.meta.device.duration ? "0" : ((data.meta.device.duration / 3600) < 1) ? "00h" : `${Math.floor((data.meta.device.duration / 3600)).toString().padStart(2, '0')}h`
      const mins = !data.meta.device.duration ? "0" : `${Math.round((data.meta.device.duration % 3600) / 60).toString().padStart(2, '0')}m`
      const duration = `${hours}:${mins}`
      const distance = !data.meta.device.tripDistance ? "0 mi" : `${data.meta.device.tripDistance.toFixed(2)} mi`
      const incidentCount = !data.meta.device.eventCount ? 0 : (data.meta.device.eventCount['total'] || "0").toString()
      const driverId = !data.meta.device.driverId ? "Unknown" : data.meta.device.driverId
      const driverName = !data.meta.device.driverName ? "Unknown" : data.meta.device.driverName
      const assetId = !data.meta.device.assetId ? "Unknown" : data.meta.device.assetId
      const deviceId = !data.meta.device.assetName ? "Unknown" : data.meta.device.assetName
      const fleetId = !data.meta.device.fleetId ? this._currentUser.fleetId : data.meta.device.fleetId
      const lastUpdated = !data.meta.device.lastUpdatedTimeUTC ? "Unknown" : data.meta.device.lastUpdatedTimeUTC
      const isDriving = !data.meta.device.deviceState ? false : data.meta.device.deviceState === "ONGOING"
      var streamButton = ""

      if (isDriving === true)
        streamButton = `<div class="icon-button-container">
          <button id="open-stream-dialog-button"
              assetId="${assetId}"
              deviceId="${assetId}"
              fleetId="${fleetId}"
              type="button">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="currentColor" d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
          </button>
          <span class="tooltiptext">${this.translate.instant('streaming.streamTrip')}</span>
        </div>`
      
      this.infoContent = this.sanitized.bypassSecurityTrustHtml( `
        <div class="info-window-table" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">
          <div><p>${this.translate.instant('trips.tripDetails')}</p></div>
          ${streamButton}
          <div class="info-window-key-nontable">
            <p>${this.translate.instant('trips.assetId')}</p>
          </div>
          <div class="info-window-value-nontable">
            <p>
              ${assetId}
            </p>
          </div>
          <div class="info-window-key-nontable">
            <p> ${this.translate.instant('drivers.driverId')}</p>
          </div>
          <div class="info-window-value-nontable">
            <p>
              ${deviceId}
            </p>
          </div>
          <div class="info-window-key-nontable">
            <p>${this.translate.instant('trips.lastUpdated')}</p>
          </div>
          <div class="info-window-value-nontable">
            <p>
              ${moment(lastUpdated).format(moment.localeData(sessionStorage.getItem('language')).longDateFormat('lll'))}
            </p>
          </div>
        </div>`)
      
      this._infoCloseSub?.unsubscribe()
      this._infoCloseSub = this.infoWindow.closeclick.pipe(take(1)).subscribe(() => {
        this._renderMarkersV2()
      })
      this.infoWindow.open(marker)
    }else{
      console.log('marker clicked!', data.meta.device)
      const hours = !data.meta.device.duration ? "0" : ((data.meta.device.duration / 3600) < 1) ? "00h" : `${Math.floor((data.meta.device.duration / 3600)).toString().padStart(2, '0')}h`
      const mins = !data.meta.device.duration ? "0" : `${Math.round((data.meta.device.duration % 3600) / 60).toString().padStart(2, '0')}m`
      const duration = `${hours}:${mins}`
      const distance = !data.meta.device.tripDistance ? "0 mi" : `${data.meta.device.tripDistance.toFixed(2)} mi`
      const incidentCount = !data.meta.device.eventCount ? 0 : (data.meta.device.eventCount['total'] || "0").toString()
      const driverId = !data.meta.device.driverId ? "Unknown" : data.meta.device.driverId
      const driverName = !data.meta.device.driverName ? "Unknown" : data.meta.device.driverName
      const assetId = !data.meta.device.assetId ? "Unknown" : data.meta.device.assetId
      const deviceId = !data.meta.device.deviceId ? "Unknown" : data.meta.device.deviceId
      const fleetId = !data.meta.device.fleetId ? "Unknown" : data.meta.device.fleetId
      const lastUpdated = !data.meta.device.lastUpdatedTimeUTC ? "Unknown" : data.meta.device.lastUpdatedTimeUTC
      const isDriving = !data.meta.device.lastUpdatedBy ? false : data.meta.device.lastUpdatedBy === "ONGOING"
      var streamButton = ""
      if (isDriving === true)
        streamButton = `<div class="icon-button-container">
          <button id="open-stream-dialog-button"
              assetId="${assetId}"
              deviceId="${deviceId}"
              fleetId="${fleetId}"
              type="button">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="currentColor" d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
          </button>
          <span class="tooltiptext">${this.translate.instant('streaming.streamTrip')}</span>
        </div>`
      this.infoContent = this.sanitized.bypassSecurityTrustHtml( `
        <div class="info-window-table" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">
          <div><p>${this.translate.instant('trips.tripDetails')}</p></div>
          ${streamButton}
          <div class="info-window-key-nontable">
            <p>${this.translate.instant('trips.assetId')}</p>
          </div>
          <div class="info-window-value-nontable">
            <p>
              ${assetId}
            </p>
          </div>
          <div class="info-window-key-nontable">
            <p> ${this.translate.instant('drivers.driverId')}</p>
          </div>
          <div class="info-window-value-nontable">
            <p>
              ${driverId}
            </p>
          </div>
          <div class="info-window-key-nontable">
            <p>${this.translate.instant('trips.lastUpdated')}</p>
          </div>
          <div class="info-window-value-nontable">
            <p>
              ${moment(lastUpdated).format(moment.localeData(sessionStorage.getItem('language')).longDateFormat('lll'))}
            </p>
          </div>
        </div>`)
      this._infoCloseSub?.unsubscribe()
      this._infoCloseSub = this.infoWindow.closeclick.pipe(take(1)).subscribe(() => {
        this._renderMarkers()
      })
      this.infoWindow.open(marker)
    }
  }

  ngAfterViewChecked (){
    var button = this.elementRef.nativeElement.querySelector('#open-stream-dialog-button')
    if(button && button.getAttribute('listener') !== 'true'){
      button.setAttribute('listener', 'true');
      button.addEventListener(
        'click',
        () => {
          this.openDialog(
            "stream-trip",
            {
              assetId: button.getAttribute('assetId'),
              deviceId: button.getAttribute('deviceId'),
              fleetId: button.getAttribute('fleetId')
            }
          )
        }
      );
    }
  }

  public onFilterUpdate(text: any) {
    this.filterAssetId = this.filterFormGroup.controls['filter'].value
    console.log('filterAssetId', this.filterAssetId)
    this._updateTableData()
    // next, filter the map markers and force the map to recalc bounds
    this.bounds = null
    if(this._fleetType){
      this._renderMarkersV2()
    }else{
      this._renderMarkers()
    }
  }

  onTabChange(tabIndex: number): void {
    // Handle the tab change event
    this.tabIndex = tabIndex;
    console.log('Tab changed to index:', tabIndex);
    if(this._fleetType){
      this._renderMarkersV2();
    }else{
      this._renderMarkers();
    }
  }

  public async openDialog(name: string, data?: any): Promise<void> {
    switch(name) {
      case "stream-trip": {
        if (this._currentUser.fleetId === data.fleetId)
          this.dialog.open(StreamingDialogComponent, {
            width:'50%',
            height:'70%',
            hasBackdrop: true,
            backdropClass: 'bdrop-soft',
            disableClose: true,
            data: { data: data, streamData: null }
          })
          ?.afterClosed().subscribe(result => {
            if(result?.data?.streamData?.streamRequestId) {
              console.log('Closing stream');
              this.fdl.stopLiveStreamV2(data.fleetId, result.data.streamData.streamRequestId).then((resp) => {
                console.log(resp);
              })
            }
          });
        else
          this.dialog.open(AlertDialogComponent, {
            data: {
              icon: 'error',
              message: 'Cannot view stream for the selected device!'
            }
          });
        break;
      }
    }
  }

  public getUser(){
    const localStorageKeys = Object.keys(localStorage);
    let lastAuthUser = null; 
    for (let i = 0; i < localStorageKeys.length; i++) {
      const key = localStorageKeys[i];
      if (key.endsWith('LastAuthUser')) {
        lastAuthUser = localStorage.getItem(key); 
        break;
      }
    }
    if (lastAuthUser) {
      console.log('Last authenticated user:', lastAuthUser);
      this.user = lastAuthUser
    }
    else
    { console.log('No last authenticated user found in local storage.'); }
  }

}

