import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuardService } from './providers/guards/auth-guard';
import { HealthcheckComponent } from './healthcheck/healthcheck.component';

const routes: VexRoutes = [
  /*
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },*/
  {
    path: 'healthcheck',
    component: HealthcheckComponent
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/home',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./dashboards/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'trips',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./dashboards/trips/trips.module').then(m => m.TripsModule),
      },
      {
        path: 'live-view',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./dashboards/live-view/live-view.module').then(m => m.LiveViewModule),
      },
      {
        path: 'review',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./dashboards/review/review.module').then(m => m.ReviewModule),
      },
      {
        path: 'drivers',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./dashboards/drivers/drivers.module').then(m => m.DriversModule),
      },
      {
        path: 'admin',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./admin/admin/admin.module').then(m => m.AdminModule),
      },
      // {
      //   path: 'reports',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./dashboards/reports/reports.module').then(m => m.ReportsModule),
      // },
      {
        path: 'fleet-assets',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./dashboards/assets/assets.module').then(m => m.AssetsModule),
      },
      {
        path: 'fleet-geofences',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./dashboards/geofences/geofences.module').then(m => m.GeofencesModule),
      },
      // {
      //   path: 'settings',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./dashboards/settings/settings.module').then(m => m.SettingsModule),
      // },
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
      },
      // },
      // {
      //   path: 'authenticated',
      //   loadChildren: () => import('./pages/auth/cognito/authenticated/authenticated.module').then(m => m.AuthenticatedModule),
      // },
      // {
      //   path: 'signedout',
      //   loadChildren: () => import('./pages/auth/cognito/signedout/signedout.module').then(m => m.SignedoutModule),
      // }
    ]
  }
]

//const routes: Routes = [
//  {
//    path: '',
//    component: CustomLayoutComponent,
//    children: []
//  }
//];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
