import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { FleetDataLayer } from 'src/app/providers/data-layers/fleet-data-layer';
import { IPaginationResult, IPolyPaginationDigest } from 'src/app/concepts/pagination/pagination';
import { Observable } from 'rxjs';
import { ILiveViewAssetTableResult } from 'src/app/concepts/live-view/live-view';
import { Router } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vex-live-view-assets-table',
  templateUrl: './live-view-assets-table.component.html',
  styleUrls: ['./live-view-assets-table.component.scss']
})
export class LiveViewAssetsTableComponent implements OnInit, AfterViewInit {
  language: string = this.translate.currentLang;

  @ViewChild('card', { static: true }) card:ElementRef

  @Input() pagination: IPaginationResult
  public activeAssetsTableData: ILiveViewAssetTableResult[]
  public activeAssetsPageData: ILiveViewAssetTableResult[]
  public nonActiveAssetsTableData: ILiveViewAssetTableResult[]
  public nonActiveAssetsPageData: ILiveViewAssetTableResult[]
  public fleetType: boolean;
  @Output() tabChange: EventEmitter<number> = new EventEmitter<number>()
  @Input() set tableData(data: ILiveViewAssetTableResult[]) {

    var recentAssets = [];
    var otherAssets = [];
    if(data?.length > 0) {
      data.sort((a, b) => a.assetId.localeCompare(b.assetId));
      const twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      data.forEach((asset, index) => {
        var lastUpdatedTimeUTC = new Date(asset['meta']['device']['lastUpdatedTimeUTC']);
        if (lastUpdatedTimeUTC >= twoWeeksAgo) {
          recentAssets.push({...asset, originalIndex: index});
        } else {
          otherAssets.push({...asset, originalIndex: index});
        }
      });
      this.activeAssetsTableData = recentAssets.map((asset, index) => ({
        ...asset,
        index: asset.index,
        livestream: this.createButton(asset)
      }));
      this.activeAssetsPageData = recentAssets?.slice(0, 10).map((asset, index) => ({
        ...asset,
        index: asset.index,
        livestream: this.createButton(asset)
      }));
      this.nonActiveAssetsTableData = otherAssets;
      this.nonActiveAssetsPageData = otherAssets?.slice(0, 10).map((asset, index) => ({
        ...asset,
        index: asset.index
      }));
      this.paginationDigestActive.table_asset_details.pageSize = 10;
      this.paginationDigestActive.table_asset_details.length = data.length;
      this.paginationDigestNonActive.table_asset_details.pageSize = 10;
      this.paginationDigestNonActive.table_asset_details.length = data.length;
    } else {
      this.activeAssetsTableData = [];
      this.nonActiveAssetsTableData = [];
      this.paginationDigestActive.table_asset_details.pageSize = 0;
      this.paginationDigestActive.table_asset_details.length = 0;
      this.paginationDigestNonActive.table_asset_details.pageSize = 0;
      this.paginationDigestNonActive.table_asset_details.length = 0;
    }
  }

  @Input() loaded$: Observable<boolean>

  public tableColumns: TableColumn<ILiveViewAssetTableResult>[] = [
    {
      label: '#',
      property: 'index',
      type: 'text'
    },
    {
      label: this.translate.instant('liveViewAssetsTable.assetId'),
      property: 'assetId',
      type: 'clickable-text',
    },
    // Disabled for V1
    // {
    //   label: 'Actions',
    //   property: 'actions',
    //   type: 'actions', // temp, need a new actions type
    // }
  ];

  public liveStreamColumns: TableColumn<ILiveViewAssetTableResult>[] = [
    ...this.tableColumns,
    {
      label: this.translate.instant('streaming.liveStreaming'),
      property: 'livestream',
      type: 'innerHTML'
    }]

  public paginationDigestActive: IPolyPaginationDigest = {
    'table_asset_details' : {
      entity: 'table_asset_details',
      page: 0,
      pageSize: 10,
      limit: 10,
      length: 0,
      lastPage: false
    }
  }

  public paginationDigestNonActive: IPolyPaginationDigest = {
    'table_asset_details' : {
      entity: 'table_asset_details',
      page: 0,
      pageSize: 10,
      limit: 10,
      length: 0,
      lastPage: false
    }
  }

  constructor(
    public fdl: FleetDataLayer,
    private router: Router,
    private sanitized: DomSanitizer,
    private translate: TranslateService,
  ) {
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  // Disabled for V1
  // public onActionClicked(eventData: any) : void {
  //   switch(eventData.actionName) {
  //     case 'live.view.trip_details': {

  //       console.log('opening trip details for', eventData.tableRow.meta.event)
  //       this.router.navigate(['/trip-details/', eventData.tableRow.meta.event.driverId, eventData.tableRow.meta.event.tripId])

  //       break;
  //     }
  //     default: {
  //       // this.snackbar.open('This feature is not yet implemented', 'GOT IT!', {
  //       //   duration: 10000
  //       // });
  //     }
  //   }
  // }

  public onPaginationRequest(paginationRequest: any) {
    switch(paginationRequest.entity) {
      case 'table_trip_details': {

        const limitActive = this.paginationDigestActive.table_trip_details.limit
        const reqPageActive = paginationRequest.page

        this.paginationDigestActive.table_trip_details.page = reqPageActive
        this.paginationDigestActive.table_trip_details.lastPage = (reqPageActive + 1) * limitActive > this.activeAssetsTableData.length
        this.activeAssetsPageData = this.activeAssetsTableData.slice(reqPageActive * limitActive, (reqPageActive + 1) * limitActive)


        const limitNonActive = this.paginationDigestNonActive.table_trip_details.limit
        const reqPage = paginationRequest.page

        this.paginationDigestNonActive.table_trip_details.page = reqPage
        this.paginationDigestNonActive.table_trip_details.lastPage = (reqPage + 1) * limitNonActive > this.nonActiveAssetsTableData.length
        this.nonActiveAssetsPageData = this.nonActiveAssetsTableData.slice(reqPage * limitNonActive, (reqPage + 1) * limitNonActive)

        break
      }
    }
  }


  createButton(asset) {
    var parentDiv = document.createElement("div")
    var div = document.createElement("div")
    div.setAttribute("class", "icon-button-container-no-spacing");

    var button = document.createElement("button")
    button.setAttribute('id', `stream-button-table-${asset["assetId"]}`);
    button.setAttribute('type', 'button');
    button.innerHTML =
      `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path fill="currentColor" d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
      </svg>`

    let isTripOngoing;

    if (asset["fleetType"]) {
      isTripOngoing = asset["meta"]["device"]["deviceState"] === "ONGOING"
      // If the trip is ongoing, the button is enabled and does something. Otherwise, disabled.
      if (isTripOngoing) {
        var tooltip = document.createElement("span")
        tooltip.setAttribute("class", "tooltiptext")
        tooltip.innerHTML = this.translate.instant('streaming.streamTrip')
        div.appendChild(tooltip)
      }
      else {
        button.setAttribute('disabled', 'true');
      }
    } else {
      let canILiveStream = asset["meta"]["device"]["lastUpdatedBy"] === "ONGOING" ||
        asset["meta"]["device"]["lastUpdatedBy"] === "PARKED"
      // If the trip is ongoing, the button is enabled and does something. Otherwise, disabled.
      if (canILiveStream) {
        var tooltip = document.createElement("span")
        tooltip.setAttribute("class", "tooltiptext")
        tooltip.innerHTML = this.translate.instant('streaming.streamTrip')
        div.appendChild(tooltip)
      }
      else {
        button.setAttribute('disabled', 'true');
      }
    }

    

    // Wrapping both the button and the tooltip (if applicable) in a div for layout purposes.
    div.appendChild(button)
    parentDiv.appendChild(div)
    
    return this.sanitized.bypassSecurityTrustHtml(parentDiv.innerHTML)
  }

}
