import { getParams } from "../generic/utils"

export interface IReqDriverTripDetails {
  includeViolations?: boolean
  includePathInfo?: boolean
  includeCustomEvents?: boolean
  includeDebug?: boolean
  includeUploadRequests?: boolean
  includeUploadStats?: boolean
  includeInertialSensorData?: boolean // TODO note in postman enabling this causes a 500 error as of 29/08/2022
  includeFRResults?: boolean
  includeTripConfig?: boolean
  includeCustomTripKeys?: boolean
  stopThreshold?: number
}

export const getParamsDriverTripDetails = (options: IReqDriverTripDetails) : string => {
  const p = getParams(options)
  return p
}

export const generateTripDetailsOptions = (options: IReqDriverTripDetails) => {
  return {...defaultReqDriverTripDetails, ...options}
}

export const defaultReqDriverTripDetails: IReqDriverTripDetails = {
  includeViolations: false,
  includePathInfo: false,
  includeCustomEvents: false,
  includeDebug: false,
  includeUploadRequests: false,
  includeUploadStats: false,
  includeInertialSensorData: false,
  includeFRResults: false,
  includeTripConfig: false,
  includeCustomTripKeys: false
}

export const withLocation: IReqDriverTripDetails = {
  includePathInfo: true,
}

export const withViolations: IReqDriverTripDetails = {
  includeViolations: true,
}

export const withViolationsEnhanced: IReqDriverTripDetails = {
  includeViolations: true,
  includeInertialSensorData: false,
}

export const withLocationsAndViolations: IReqDriverTripDetails = {
  includePathInfo: true,
  includeViolations: true,
  stopThreshold: 30000
}

export const withLocationsAndEnhancedViolations: IReqDriverTripDetails = {
  includePathInfo: true,
  includeViolations: true,
  includeInertialSensorData: false,
}

// Generated by https://quicktype.io

export interface IResDriverTripDetails {
  driverviolations: Violation[];
  distance: any
  assetId: string
  data: any;
  duration:                         number;
  tripId:                           string;
  fleetId:                          string;
  driverId:                         string;
  driverName:                       string;
  tripDistance:                     number;
  startTime:                        string;
  endTime:                          string;
  startTimeUTC:                     string;
  endTimeUTC:                       string;
  timezoneOffset:                   number;
  eventCount:                       { [key: string]: number };
  customEventCount:                 CustomEventCount;
  cameraSerialId:                   string;
  asset:                            Asset;
  dvrEnabled:                       boolean;
  ongoing:                          boolean;
  timeZoneId:                       string;
  device:                           Device;
  cameraMountingStatusFiles:        CameraMountingStatusFile[];
  ignitionId:                       string;
  statusUpdateTimestamp:            string;
  sampleDriverImage:                string;
  sampleDriverImageFaceCoordinates: SampleDriverImageFaceCoordinates;
  firstLocation:                    Location;
  lastLocation:                     Location;
  lastKnownLocation:                Location;
  cameraConnected:                  boolean;
  debug:                            any[];
  violations:                       Violation[];
  pathInfo:                         PathInfo[];
  fullPathAvailable:                boolean;
  localRouteFiles:                  any[];
  uploadRequests:                   any[];
  customEvents:                     any[];
  edvrEnabled:                      boolean;
  config:                           Config;
  cameraMountingStatus:             CameraMountingStatus;
  videoCaptureStartTimeUTC:         string;
  videoCaptureStartTime:            string;
  tripDataUploaded:                 boolean;
  frResults:                        any[];
  violationFileUploadStat:          NFileUploadStat;
  binFileUploadStat:                NFileUploadStat;
  stops:                            any[];
}

export interface Asset {
  assetId:         string;
  dutyType:        DutyType;
  cameraType:      CameraType;
  ridecamPlusPlan: RidecamPlusPlan;
  plusPackages:    Package[];
  packages:        Package[];
  metadata:        any;
}

export enum CameraType {
  RidecamPlus = "RIDECAM_PLUS",
}

export enum DutyType {
  Light = 'light',
  Meduim = 'medium',
  Heavy = "heavy",
}

export enum Package {
  AdasTg = "ADAS_TG",
  DVR = "DVR",
  DmsDistraction = "DMS_DISTRACTION",
  DmsFaceid = "DMS_FACEID",
  Edvr = "EDVR",
  GSensor = "G-sensor",
}

export enum RidecamPlusPlan {
  Pro = "PRO+",
}

export interface NFileUploadStat {
  totalCount:    number;
  uploadedCount: number;
}

export interface CameraMountingStatus {
  eventVideoFile: string;
  isBadMounting:  boolean;
}

export interface CameraMountingStatusFile {
  isBadMounting:  boolean;
  source:         Source;
  mediaFileIndex: number;
  mediaFile:      string;
}

export enum Source {
  SideBySide = "SIDE_BY_SIDE",
}

export interface Config {
  'Cellphone-Distracted-Driving': ConfigOptions
  'Cornering': ConfigOptions
  'Distracted-Driving': ConfigOptions
  'Drowsy-Driving-Detected': ConfigOptions // #NESTLE
  'Forward-Collision-Warning': ConfigOptions
  'Harsh-Acceleration': ConfigOptions
  'Harsh-Braking': ConfigOptions
  'Harsh-Cornering': ConfigOptions
  'Lane-Drift-Found': ConfigOptions
  'MaxSpeedExceeded': ConfigOptions
  'Speed-Limit-Violation': ConfigOptions
  'Stop-Sign-Violation': ConfigOptions
  'Tailgating': ConfigOptions
  'Driver-SeatBelt-Non-Compliance': ConfigOptions
  'Passenger-SeatBelt-Non-Compliance': ConfigOptions
  'Camera-Lens-Covered': ConfigOptions
  'Device-Off-Or-Disconnected': ConfigOptions
  'Smoking-Distracted-Driving': ConfigOptions,
}

export interface ConfigOptions {
  edvrEnabled: boolean;
}

export interface CustomEventCount {
  total: number;
}

export interface Device {
  model:    string;
  deviceId: string;
}

export interface Location {
  latitude:  number;
  longitude: number;
  bearing:   number;
  speed:     number;
  accuracy:  number;
}

export interface PathInfo {
  timeOfDay:             string;
  timestampUTC:          number;
  latitude:              number;
  longitude:             number;
  speed:                 number;
  bearing:               number;
  accuracy:              number;
  cameraConnectionState: CameraConnectionState;
}

export enum CameraConnectionState {
  Connected = "CONNECTED",
}

export interface SampleDriverImageFaceCoordinates {
  topLeftX:     number;
  topLeftY:     number;
  bottomRightX: number;
  bottomRightY: number;
}

export interface Violation {
  tripId:                         string
  eventType:                      string;
  timestamp:                      string;
  timestampUTC:                   string;
  longitude:                      number;
  latitude:                       number;
  bearing:                        number;
  speed:                          number;
  accuracy:                       number;
  challengeRaised:                boolean;
  challengeResolved:              boolean;
  challengeAccepted:              boolean;
  asset:                          Asset;
  harshAccelerationValue?:        number;
  eventIndex:                     number;
  mediaFiles:                     MediaFile[];
  reportBug?:                     boolean;
  eventVideoFile:                 string;
  bookmark:                       boolean;
  coachingCompleted:              boolean;
  tags:                           any[];
  eventVideoFilename:             string;
  videoDetails:                   VideoDetails;
  eventVideoFilenameUploadStatus: EventVideoFilenameUploadStatus;
  corneringAccelerationValue?:    number;
  harshBrakingAccelerationValue?: number;
  severity?:                      number;
}

export interface EventVideoFilenameUploadStatus {
  status:          Status;
  uploadTimestamp: string;
}

export enum Status {
  Uploaded = "UPLOADED",
}

export interface MediaFile {
  mediaFileIndex: number;
  mediaFile:      string;
  source:         Source;
  videoDetails:   VideoDetails;
  uploadStats:    EventVideoFilenameUploadStatus;
}

export interface VideoDetails {
  videoWidth:     number;
  videoHeight:    number;
  videoBitrate:   number;
  includesPII:    boolean;
  videoQuality:   number;
  videoType:      VideoType;
  videoPiPType:   VideoPiPType;
  codec_type:     CodecType;
  videoFrameRate: number;
  piiSubframe:    PiiSubframe;
}

export enum CodecType {
  H264 = "h264",
}

export interface PiiSubframe {
  topLeftX: number;
  topLeftY: number;
  width:    number;
  height:   number;
}

export enum VideoPiPType {
  Road = "road",
}

export enum VideoType {
  SideBySide = "sideBySide",
}
