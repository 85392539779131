import { AfterViewInit, Component, EventEmitter, Input, Output, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild, Injectable, OnDestroy, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TableColumn } from '../../../interfaces/table-column.interface';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { IPaginationConcept, IPolyPaginationRequest, IPolyPaginationResponse } from 'src/app/concepts/pagination/pagination';
import { Observable, of, Subscription, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LiveViewComponent } from 'src/app/dashboards/live-view/live-view.component';
import { FleetDataLayer } from 'src/app/providers/data-layers/fleet-data-layer';
import { MatDialog } from '@angular/material/dialog';
import { StreamingDialogComponent } from 'src/app/dashboards/live-view/dialogs/streaming/streaming-dialog.component';

export interface ITableExpander {
  columnTrigger: string;
  template: TemplateRef<any>;
}

export interface IAction {
  icon: string
  color: string
  tooltip: string
}

export interface IActionCollection {
  [key: string]: IAction
}

@Component({
  selector: 'vex-widget-table',
  templateUrl: './widget-table.component.html',
  styleUrls: ['./widget-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '600px'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class WidgetTableComponent<T> implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() data: T[];
  @Input() columns: TableColumn<T>[];
  @Input() expandRowOnToggle: ITableExpander = null;
  @Input() paginationResponse: IPolyPaginationResponse
  @Input() public hidePagination: Boolean = false
  public isLoaded: Boolean
  @Input() set loaded(state: Boolean) {
    this.isLoaded = state
  }
  @Output() actionClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() page: EventEmitter<any> = new EventEmitter<any>();
  @Output() paginationRequest: EventEmitter<IPolyPaginationRequest> = new EventEmitter<IPolyPaginationRequest>();


  visibleColumns: Array<keyof T | string>;
  dataSource = new MatTableDataSource<T>();
  expandedElement: ITableExpander | null;
  actions: IActionCollection = {
    'assets.manage.edit_asset': {
      icon: 'edit',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.EditAsset')
    },
    'assets.manage.delete_asset': {
      icon: 'delete',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.DeleteAsset')
    },
    'drivers.overview.coaching.play_video': {
      icon: 'play_circle_outline',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Playvideo')
    },
    'drivers.overview.coaching.open_image': {
      icon: 'photo',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Openimage')
    },
    'drivers.overview.coaching.trip_details': {
      icon: 'launch',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Tripdetails')
    },
    'drivers.manage.trip_details': {
      icon: 'edit',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Modifyimages')
    },
    'drivers.manage.edit_driver': {
      icon: 'edit',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Editdriver')
    },
    'drivers.manage.delete_driver': {
      icon: 'delete',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Deletedriver')
    },
    'drivers.manage.view_driver_images': {
      icon: 'photo',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Viewdriverimages')
    },
    'review.indcidents.play_video': {
      icon: 'play_circle_outline',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Playvideo')
    },
    'review.incidents.open_image': {
      icon: 'play_circle_outline',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Openimage')
    },
    'review.incidents.trip_details': {
      icon: 'launch',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Tripdetails')
    },
    'trip.details.play_video': {
      icon: 'play_circle_outline',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Playvideo')
    },
    'trip.details.show_image': {
      icon: 'photo',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Openimage')
    },
    'trips.trip_details': {
      icon: 'launch',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Tripdetails')
    },
    'trips.edit_driver_details': {
      icon: 'edit',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Editdriverdetails')
    },
    'live.view.trip_details': {
      icon: 'launch',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Tripdetails')
    },
    'geofence.delete': {
      icon: 'delete',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Deletegeofence')
    },
    'geofence.edit': {
      icon: 'edit',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Editgeofence')
    },
    'geofence.find': {
      icon: 'my_location',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.Findgeofence')
    },
    'stopMarker.find': {
      icon: 'my_location',
      color: 'accent',
      tooltip: this.translate.instant('actionCollection.FindStop')
    },
  }

  public tippyProps = {
    theme: 'light'
  }

  public noDataMessage: string = "No Data!"

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  language: string = this.translate.currentLang;

  constructor(
    private fdl: FleetDataLayer,
    public dialog: MatDialog,
    private elementRef: ElementRef,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
   }

  // public isLoaded: boolean = false;
  public sub: Subscription
  ngOnInit() {
    // this.sub = this.loaded$.subscribe((res) => {
    //   this.isLoaded = res
    //   console.log('Table Data Loaded', this.isLoaded)
    // })
  }

  ngOnDestroy() {
    // this.sub.unsubscribe()
  }

  // set default 'no data message' to 'loading', if changes has a data entry and that entry is [] then set to 'no data'
  // if data is not [] then set to null? shouyld not require

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.visibleColumns = this.columns.map(column => column.property);
    }

    if (changes.data) {
      this.dataSource.data = this.data;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngAfterViewChecked() {
    if (this.dataSource.data.length > 0) {
      const twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      // If at least one element is less than 2 weeks old, it means that we're in the "active" tab,
      // therefore we need to add buttons next to each device.
      // This also checks that we are in the correct list, by the nesting and the presence of "lastUpdatedTimeUTC".
      if (this.isLiveViewAsset(this.dataSource?.data[0]))
        if (new Date(this.dataSource?.data[0]["meta"]["device"]["lastUpdatedTimeUTC"]) >= twoWeeksAgo) {
          this.dataSource.data.forEach((asset) => {
            this.assignActionToButton(asset)
          })
        }
    }

  }

  public onPageEvent(event: any) {
    this.paginationRequest.emit({
      entity: this.paginationResponse.entity,
      page: event.pageIndex
    })
  }

  debug(a: any, b?: any, c?:any) {
    console.log('a', a, 'b', b, 'c', c)
  }

  // todo, change this to an event emitter
  onButtonClick(column: TableColumn<T>, row: ITableExpander) {
    if(this.expandRowOnToggle?.columnTrigger === column.label) {
      this.expandedElement = this.expandedElement === row ? null : row
    }
    column?.callback()
  }

  onActionSelected(label: string, dataRow: any) : void {
    this.actionClicked.emit({actionName: label, tableRow: dataRow})
  }

  assignActionToButton(asset) {
    var button = this.elementRef.nativeElement.querySelector(`#stream-button-table-${asset["assetId"]}`)
    // Checking to see that the button hasn't already been assigned an action (this function gets called quite a few times so we don't want
    // to end up having 200 actions on a single click). If we can find one that already has a listener we do nothing.
    // If the trip is ongoing, the button is enabled and does something. Otherwise, disabled.
    if (asset["fleetType"])
      if (button.getAttribute('listener') !== 'true' && asset["meta"]["device"]["deviceState"] === 'ONGOING') {
        button.setAttribute('listener', 'true');
        button.addEventListener(
          'click',
          () => {
            this.openDialog(
              "stream-trip",
              {
                assetId: asset["assetId"],
                deviceId: asset["meta"]["device"]["assetId"],
                fleetId: asset["fleetId"]
              }
            )
          }
        );
      }
    else
      if (button.getAttribute('listener') !== 'true' && (
        asset["meta"]["device"]["lastUpdatedBy"] === "ONGOING" ||
        asset["meta"]["device"]["lastUpdatedBy"] === "PARKED"
      )) {
        button.setAttribute('listener', 'true');
        button.addEventListener(
          'click',
          () => {
            this.openDialog(
              "stream-trip",
              {
                assetId: asset["assetId"],
                deviceId: asset["meta"]["device"]["deviceId"],
                fleetId: asset["meta"]["device"]["fleetId"]
              }
            )
          }
        );
      }
  }

  public async openDialog(name: string, data?: any): Promise<void> {
    switch(name) {
      case "stream-trip": {
          this.dialog.open(StreamingDialogComponent, {
            width:'50%',
            height:'70%',
            hasBackdrop: true,
            backdropClass: 'bdrop-soft',
            disableClose: true,
            data: { data: data, streamData: null }
          })
          ?.afterClosed().subscribe(result => {
            if(result?.data?.streamData?.streamRequestId) {
              console.log('Closing stream');
              this.fdl.stopLiveStreamV2(data.fleetId, result.data.streamData.streamRequestId).then((resp) => {
                console.log(resp);
              })
            }
          });
        break;
      }
    }
  }

  private isLiveViewAsset(dataType) {
    return !dataType.hasOwnProperty("meta") ? false : (!dataType["meta"].hasOwnProperty("device") ? false : dataType["meta"]["device"].hasOwnProperty("lastUpdatedTimeUTC"))
  }

  get template() {
    return this.expandRowOnToggle.template
  }

  get context(){
    return this.expandRowOnToggle;
  }

}
@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  getRangeLabel=(page:number, pageSize:number, length:number)=>{
    const f = 1 + page * pageSize
    const t = (1 + page) * pageSize > length ? length : (1 + page) * pageSize

    return `${f} - ${t}`

  }
}
