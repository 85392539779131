import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { DurationSelectorComponent } from './components/duration-selector/duration-selector.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DurationSelectorModule } from './components/duration-selector/duration-selector.module';
import { QuadStatsModule } from './components/trips/quad-stats/quad-stats.module';
import { HighlightsModule } from './components/trips/highlights/highlights.module';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { AuthGuardService } from './providers/guards/auth-guard';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { CognitoService } from './providers/services/cognito-service';
import { CognitoOldService } from './providers/services/cognito-old-service';
import { LightmetricsService } from './providers/services/lightmetrics-service';
import { VikingAPIService } from './providers/services/viking-api-service';
import { ApiChooserService } from './providers/services/api-chooser-service';
import { FleetDataLayer } from './providers/data-layers/fleet-data-layer';
import { PaginationService } from './providers/services/pagination-service';
import { GeocodingService } from './providers/services/geocoding.service';
import { S3Service } from './providers/services/s3-service';
import { TableStateService } from './providers/services/table-state.service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { UserService } from './providers/services/user-service';
import { YesnoDialogComponent } from './common/dialogs/yesno-dialog/yesno-dialog.component';
import { HealthcheckComponent } from './healthcheck/healthcheck.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    YesnoDialogComponent,
    HealthcheckComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatDialogModule,
    NgxTippyModule,
    // MatTooltipModule,
    // Internals
    DurationSelectorModule,
    QuadStatsModule,
    HighlightsModule,
    // Vex
    VexModule,
    CustomLayoutModule,
    TranslateModule.forRoot({
      //defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  exports: [
    DurationSelectorComponent,
    TranslateModule
  ],
  providers: [
    AuthGuardService,
    CognitoOldService,
    UserService,
    CognitoService,
    LightmetricsService,
    VikingAPIService,
    ApiChooserService,
    FleetDataLayer,
    PaginationService,
    GeocodingService,
    S3Service,
    TableStateService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private authService: CognitoService,
  ) {
    this.authService.isAuthenticated()
  }
}
